<template>
  <div>
    <p class="titleFont">核对订单</p>
    <div class="qyueBox">
      <p class="title">签约信息</p>
      <!-- end -->
      <ul class="imgTitle">
        <li class="left">
          <img v-if="listArr.op_role == 8" :src="GLOBAL.imgURL + 'operateOper.png'" alt="" />
          <img v-if="listArr.op_role == 3" :src="GLOBAL.imgURL + 'operate.png'" alt="" />
        </li>
        <li class="right">
          <p class="moe_bottom">
            签约城市:<span class="textList">{{
              listArr.province == null
                ? "全国"
                : listArr.province + listArr.city + listArr.area
            }}</span>
          </p>
          <p class="moe_bottom">
            合同编号:<span class="textList">{{ listArr.contract_sn }}</span>
          </p>
          <p class="textList textList1">
            {{ listArr.truename }}: {{ listArr.phone }}
          </p>
        </li>
      </ul>
      <p class="title title1">凭证信息</p>
      <!-- end -->
      <ul class="ulImg">
        <li v-for="(item, index) in imgArr" :key="index">
          <img :src="item" alt="" />
        </li>
      </ul>
    </div>

    <!-- <van-progress pivot-text="" :percentage="value" stroke-width="8" /> -->
    <el-progress
      :percentage="percentage"
      :stroke-width="10"
      :show-text="false"
    ></el-progress>
    <!-- end -->
  </div>
</template>

<script>
import { Progress } from "vant";
export default {
  props: {
    listArr: {
      default: true,
    },
    imgArr: {
      default: true,
    },
  },
  data() {
    return {
      percentage: 1,
      timer: "",
      show: false,
    };
  },
  mounted() {
    this.percentage = 0;
    this.timer = setInterval(this.timerFun, 20);
  },
  methods: {
    timerFun() {
      this.percentage++;
      //   console.log(this.percentage);
      if (this.percentage == 100) {
        clearInterval(this.timer);
        this.show = true;
        this.$emit("second", this.show);
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped>
.titleFont {
  font-size: 0.45rem;
  text-align: center;
  border-bottom: 1px solid #f3f3f5ff;
  line-height: 1rem;
}
.qyueBox {
  color: #000a33ff;
  padding: 0.3rem 0.3rem;
  box-sizing: border-box;
}
.qyueBox .title {
  font-size: 0.4rem;
  padding-left: 0.3rem;
  position: relative;
}
.qyueBox .title::before {
  content: "";
  background: #0e8affff;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 52%;
  transform: translate(0, -50%);
  width: 5px;
  height: 20px;
  border-radius: 10px;
}
.imgTitle {
  margin: 0.3rem 0;
  overflow: hidden;
}
.imgTitle .left {
  float: left;
}
.imgTitle .left img {
  width: 75px;
  height: 75px;
}
.imgTitle .right {
  margin-left: 85px;
  font-size: 0.4rem;
  color: #333b5cff;
}
.right .textList {
  color: #000a33ff;
}
.right .moe_bottom {
  margin-bottom: 2px;
}
.textList1 {
  margin-top: 6px;
}
.ulImg {
  overflow: hidden;
  width: 100%;
  margin: 0.2rem 0;
}
.ulImg li {
  float: left;
  margin-right: 10px;
}
.ulImg img {
  width: 90px;
  height: 90px;
  border-radius: 10px;
}
.title1{
  margin-top: 20px;
}
</style>